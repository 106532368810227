<!--轮播管理-->
<template>
    <div class="tableBox" style="paddingTop: 30px;">
        <a-form ref="formRef" :model="form">
            <a-form-item label="上 传 轮 播 图 片">
                <a-space class="flex flex-wrap">
                    <div v-for="(item,index) in form.img">
                        <a-row class="MaskBox">
                            <img class="hoverImage" :src="domain+item.img_url" />
                            <a-space class="mask-content flex flex-jc-c flex-ai-c">
                                <a-upload-dragger name="file_name" style="width:40px;height:40px" :show-upload-list="false" :customRequest="uploadImg">
                                    <icon-font type="icon-edit" class="iconFont" @click="editImg(index)" />
                                </a-upload-dragger>
                                <icon-font type="icon-delete" class="iconFont" @click="deleteImg(index)" />
                            </a-space>
                        </a-row>
                        <a-select style="margin-top:10px" v-model:value="item.article_id" allowClear showSearch :filter-option="filterOption" placeholder="选择跳转文章链接">
                            <a-select-option v-for="(item,idx) in article" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                        </a-select>
                    </div>

                    <a-upload-dragger name="file_name" style="width:375px;height:166px" :show-upload-list="false" :customRequest="uploadImg">
                        <p class="ant-upload-drag-icon">
                            <inbox-outlined></inbox-outlined>
                        </p>
                        <p class="ant-upload-text">将图片拖到此处或点击上传</p>
                    </a-upload-dragger>
                </a-space>
            </a-form-item>
            <div style="width:40%;padding:50px 0 50px 136px">
                <a-button block type="primary" @click="saveImage">保存</a-button>
            </div>
        </a-form>

    </div>
</template>
<script>
import { InboxOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, toRefs, reactive, getCurrentInstance, onMounted } from 'vue';

export default defineComponent({
    components: {
        InboxOutlined,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const initData = reactive({
            form: {
                img: []
            },
            article: [],
            loading: false,
            domain: proxy.domain,
            CurrentEditId: -1
        });
        onMounted(() => {
            BannerList();
            ArticleList();
        });
        const BannerList = () => {
            proxy.http.post('/admin.banner/index').then(response => {
                initData.form.img = response;
            })
        };
        const ArticleList = () => {
            proxy.http.post('/admin.article/indexAll').then(response => {
                initData.article = response;
            })
        }
        //编辑图片
        const editImg = (index) => {
            initData.CurrentEditId = index;
        }
        //上传图片
        const uploadImg = (info) => {
            initData.loading = true;
            let file = new FormData();
            file.append('file_name', info.file);
            file.append('file_type', 'banner')
            proxy.http.post('/upload/upload', file).then((response) => {
                if (initData.CurrentEditId >= 0) initData.form.img[initData.CurrentEditId] = response.path
                else initData.form.img.push({
                    img_url: response.path
                });
                initData.loading = false;
            })
        };
        //删除图片
        const deleteImg = (index) => {
            initData.form.img.splice(index, 1)
        };

        //下拉筛选过滤
        const filterOption = (input, option) => {
            return (
                option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        };

        const saveImage = () => {
            proxy.http.post('/admin.banner/save', initData.form).then(() => {
                proxy.message.success('保存成功');
                BannerList();
            })
        }
        return {
            ...toRefs(initData),
            filterOption,
            BannerList,
            ArticleList,
            uploadImg,
            editImg,
            deleteImg,
            saveImage
        };
    },
});
</script>
<style lang="less" scoped>
.ant-upload-text {
    color: #606266 !important;
    font-size: 14px !important;
}
// :deep(.ant-form-item-control-input-content) {
//     height: calc(100vh - 180px);
//     overflow: auto;
// }
:deep(.ant-upload) {
    height: 166px;
}
.MaskBox {
    position: relative;
    cursor: pointer;
    :deep(.ant-upload-drag) {
        background: transparent !important;
        border: 0;
    }
    .hoverImage {
        width: 375px;
        height: 124px;
        object-fit: cover;
        border-radius: 4px;
    }
    .mask-content {
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        background: rgba(101, 101, 101, 0.6);
        color: #ffffff;
        opacity: 0;
        border-radius: 4px;
        .iconFont {
            font-size: 25px;
        }
    }
}
.MaskBox:hover .mask-content {
    opacity: 1;
    transition: all 0.5s;
}
</style>